<template>
    <div class="blog-article-page">
        <div class="inner_wrapper">
            <Slider class="slider" v-if="currentBlog.pictures.length">
                <SwiperSlide v-for="image in currentBlog.pictures" :key="image.id">
                    <SliderCard class="slider-card" :image="image" :with-social-icons="false" />
                </SwiperSlide>
            </Slider>
            <Slider class="slider" v-else>
                <SliderCard class="slider-card" />
            </Slider>

            <div class="content__wrapper">
                <h6>{{ currentBlog.createdAt }}</h6>
                <div class="title__wrapper">
                    <h1>{{ $filters.getTranslationByKey(currentBlog.title) }}</h1>
                    <SocialShareBlock class="social-block" :title="$filters.getTranslationByKey(currentBlog.title)" />
                </div>
                <div v-html="currentBlog.content[$i18n.locale]" />
            </div>
        </div>
    </div>
</template>

<script>
    import SocialShareBlock from '@/components/Common/SocialShareBlock/SocialShareBlock.vue';
    import { SwiperSlide } from 'swiper/vue/swiper-slide';
    import Slider from '@/components/Common/Slider/Slider.vue';
    import SliderCard from '@/components/MealKits/SliderCard.vue';
    import { mapGetters } from 'vuex';

    export default {
        name: 'BlogArticle',
        components: { SwiperSlide, Slider, SliderCard, SocialShareBlock },
        computed: {
            ...mapGetters('blogArticle', ['currentBlog']),
        },
    };
</script>

<style lang="scss" scoped>
    .blog-article-page {
        padding-top: 50px;
        padding-bottom: 50px;

        .inner_wrapper {
            max-width: 900px;
            margin: 0 auto;

            .content__wrapper {
                max-width: 800px;
                margin: 0 auto;

                h6 {
                    color: $grey-form-label;
                    margin-bottom: 8px;
                }

                .title__wrapper {
                    @include row-align-center-justify-between;
                    flex-wrap: wrap;

                    width: 100%;

                    margin-bottom: 22px;

                    h1,
                    .social-block {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        // slider
        .slider {
            border-radius: $br-20;
        }

        :deep(.slider) {
            border-radius: $br-20;
            padding-bottom: 25px;
        }

        :deep(.slider .swiper-pagination-bullet) {
            text-align: center;
            line-height: 40px;
            font-size: 24px;
            opacity: 1;
            background: $grey-form;
        }
        :deep(.slider .swiper-pagination-bullet-active) {
            background: $primary;
        }

        :deep(.swiper-pagination) {
            bottom: 0px;
        }
    }

    @include media($lg) {
        .slider {
            .slider-card {
                border-radius: $br-15;

                height: 500px;
                padding: 10px;
            }
        }
    }
</style>
